<template>
  <div class="add-event add-template">
    <g-background>
      <page-header>
        <h1>{{ title }}</h1>
      </page-header>
      <page-body>
        <b-form class="member-type-form">
          <b-form-group :label="translations.tcEventType" class="font-style-2 mb-0">
            <b-form-select v-model="event" :options="eventTypeTranslated" :state="event !== null"
              class="form-control g-select"></b-form-select>
          </b-form-group>
          <b-form-group :label="translations.tcEventTitle" class="font-style-2 mb-0">
            <b-form-input v-model="eventTitle"
              :state="event !== GideonOtherMeetingGuid || (eventTitle !== null && eventTitle !== '')" id="event-input"
              :placeholder="translations.tcEventTitle"></b-form-input>
          </b-form-group>
        </b-form>
        <b-form class="member-type-form">
          <b-form-group :label="translations.tcMemberType" class="font-style-2 mb-0">
            <b-form-select v-model="member" :state="member !== null" :options="memberType"
              class="form-control g-select"></b-form-select>
          </b-form-group>
        </b-form>
        <hr />
        <section class="form-block">
          <div class="h-style-1">{{ translations.tcLocation }}</div>
          <div>
            <b-form-radio-group id="radios-location-type" v-model="locationTypeSelected" :options="locationTypeOptions"
              button-variant="outline-primary" name="adios-location-type" size="sm" buttons></b-form-radio-group>
          </div>
          <div id="loc-physical" v-if="locationTypeSelected === 'physical'">
            <p class="mb-3">{{ locationDisplay }}</p>
            <b-form class="event-type-form">
              <location-search @location-select="handleLocationSelect" :cnt_key="this.userCountryKey"
                :i18n="translations.components" />
              <b-button variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-0 mt-4 mt-lg-0 mx-lg-4"
                @click="addLocation">{{ translations.tcAddNewLocation }}
              </b-button>
            </b-form>
          </div>
          <div id="loc-virtual" v-if="locationTypeSelected === 'virtual'">
            <p class="mb-3">{{ locationVirtualDisplay }}</p>
            <b-form class="event-type-form">
              <location-virtual-search @location-virtual-select="handleVirtualLocationSelect"
                :cnt_key="this.userCountryKey" :i18n="translations.components" />
              <b-button variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-0 mt-4 mt-lg-0 mx-lg-4"
                @click="addLocationVirtual">{{ translations.tcAddNewVirtualLocation }}
              </b-button>
            </b-form>
          </div>
        </section>
        <hr />
        <section class="form-block">
          <div class="h-style-1">{{ translations.tcCoordinator }}</div>
          <b-container class="bv-example-row">
            <b-row class="text-center">
              <b-col cols="4">
                <model-select class="form-control" :options="coordinators" v-model="coordinator"
                  :placeholder="translations.tcSelectCoordinator">
                </model-select>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <hr />
        <section class="form-block">
          <b-form>
            <div class="h-style-1" v-if="recurrenceType === 'multiple' || recurrenceType === null">
              {{ translations.tcDateRecurrence }}
            </div>
            <b-form-checkbox type="checkbox" v-model="isMultiDayEvent" v-if="recurrenceType === null">
              {{ translations.tcCheckIfRecurring }}
            </b-form-checkbox>
            <b-form-group :label="isMultiDayEvent ? translations.tcStartEndDate : translations.tcDate"
              class="font-style-2 mt-3 mb-0">
              <div id="dpContainer" class="dp-container">
                <div id="dpStart" class="dp-startend">
                  <b-form-datepicker id="startDate" v-model="startEndDate.startDate" class="mb-2 contact_date"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :locale="prefCulture" :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader" size="sm">
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
                <div id="dpEnd" class="dp-startend">
                  <b-form-datepicker id="endDate" v-model="startEndDate.endDate" class="mb-2 contact_date"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :locale="prefCulture" :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader" size="sm" v-if="isMultiDayEvent">
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
              </div>
            </b-form-group>
            <b-form-group :label="translations.tcStartTimeEndTime" class="font-style-2 mb-0">
              <div id="dpContainer" class="dp-container">
                <div id="dpStart" class="dp-startend">
                  <b-form-timepicker v-model="startTime" minutes-step="5" :locale="prefCulture"
                    :label-close-button="translations.tcClose" :label-no-time-selected="translations.tcNoTimeSelected">
                  </b-form-timepicker>
                </div>
                <div id="dpStart" class="dp-startend">
                  <b-form-timepicker v-model="endTime" minutes-step="5" :locale="prefCulture"
                    :label-close-button="translations.tcClose" :label-no-time-selected="translations.tcNoTimeSelected">
                  </b-form-timepicker>
                </div>
              </div>
            </b-form-group>
            <p class="mt-2 mb-1" v-if="isMultiDayEvent">{{ translations.tcSelectFrequency }}:</p>
            <div v-if="recurrenceType == 'multiple' || (recurrenceType == null && isMultiDayEvent)">
              <b-button-group>
                <b-button :variant="swap._onceshowstate" @click="changereoccurstate('once')">
                  {{ translations.tcOnce }}
                </b-button>
                <b-button :variant="swap._dailyshowstate" @click="changereoccurstate('daily')">
                  {{ translations.tcDaily }}
                </b-button>
                <b-button :variant="swap._weekshowstate" @click="changereoccurstate('weekly')">
                  {{ translations.tcWeekly }}
                </b-button>
                <b-button :variant="swap._monthshowstate" @click="changereoccurstate('monthly')">
                  {{ translations.tcMonthly }}
                </b-button>
                <b-button :variant="swap._yearshowstate" @click="changereoccurstate('yearly')">
                  {{ translations.tcYearly }}
                </b-button>
              </b-button-group>
              <b-form-group class="form-element every-week-on" v-show="dailyshowstate" ref="daily">
                <span>{{ translations.tcEvery }}</span>
                <b-form-input v-model="rSettings.config.daily.every" class="every-input"></b-form-input>
                <span>{{ translations.tcDayss }}</span><!-- tcDayss is not a typo -->
              </b-form-group>
              <b-form-group class="form-element every-week-on" v-show="weekshowstate" ref="weekly">
                <span>{{ translations.tcEvery }}</span>
                <b-form-input v-model="rSettings.config.weekly.every" class="every-input"> </b-form-input>
                <span>{{ translations.tcWeeksOn }}</span>
                <b-form-checkbox-group id="checkbox-group-2" name="flavour-2">
                  <b-form-checkbox v-for="day in days" :value="day.value" :key="day.value" v-model="weeklyDay">
                    <span>{{ day.text }}</span>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group class="form-element every-week-on" v-show="monthshowstate" ref="monthly">
                <b-form-radio @change="clearType" v-model="rSettings.config.monthly.type" value="type_1" name="option"
                  checked="checked"></b-form-radio>
                <span>{{ translations.tcDay }}</span>
                <b-form-input v-model="rSettings.config.monthly.mtype_1.day" class="mx-2 every-input"></b-form-input>
                <span>{{ translations.tcOfEvery }}</span>
                <b-form-input v-model="rSettings.config.monthly.mtype_1.months" class="mx-2 every-input"></b-form-input>
                <span>{{ translations.tcMonths }}</span>
              </b-form-group>
              <b-form-group class="form-element every-week-on" v-show="monthshowstate" ref="monthly">
                <b-form-radio @change="clearType" v-model="rSettings.config.monthly.type" value="type_2" name="option">
                </b-form-radio>
                <span>{{ translations.tcThe }}</span>
                <b-form-select v-model="rSettings.config.monthly.mtype_2.when" :options="ordinal"
                  class="form-control g-select"></b-form-select>
                <b-form-select v-model="rSettings.config.monthly.mtype_2.weekday" :options="days"
                  class="form-control g-select mx-2"></b-form-select>
                <span>{{ translations.tcOfEvery }}</span>
                <b-form-input v-model="rSettings.config.monthly.mtype_2.months" class="mx-2 every-input"></b-form-input>
                <span>{{ translations.tcMonths }}</span>
              </b-form-group>
              <b-form-group class="form-element every-week-on" v-show="yearshowstate" ref="yearly">
                <span>{{ translations.tcRecurEvery }}</span>
                <b-form-input v-model="rSettings.config.yearly.years" class="every-input"></b-form-input>
                <span>{{ translations.tcYear }}</span>
              </b-form-group>
              <b-form-group class="form-element every-week-on" v-show="yearshowstate" ref="yearly">
                <b-form-radio v-model="rSettings.config.yearly.type" id="_option2" name="_option" value="type_1"
                  @change="clearTypeYear"></b-form-radio>
                <span for="_option2">{{ translations.tcOn }}</span>
                <b-form-select v-model="rSettings.config.yearly.ytype_1.month" :options="months"
                  class="form-control g-select mx-2"></b-form-select>
                <b-form-input v-model="rSettings.config.yearly.ytype_1.day" class="every-input"></b-form-input>
              </b-form-group>
              <b-form-group class="form-element every-week-on" v-show="yearshowstate" ref="yearly">
                <b-form-radio v-model="rSettings.config.yearly.type" id="_option1" name="_option" value="type_2"
                  @change="clearTypeYear"></b-form-radio>
                <span for="_option1">{{ translations.tcOnThe }}</span>
                <b-form-select v-model="rSettings.config.yearly.ytype_2.when" :options="ordinal"
                  class="form-control g-select"></b-form-select>
                <b-form-select v-model="rSettings.config.yearly.ytype_2.weekday" :options="days"
                  class="form-control g-select mx-2"></b-form-select>
                <span>{{ translations.tcOf }}</span>
                <b-form-select v-model="rSettings.config.yearly.ytype_2.month" :options="months"
                  class="form-control g-select mx-2"></b-form-select>
              </b-form-group>
            </div>
          </b-form>
        </section>
        <section class="form-buttons">
          <b-button v-if="isEditMode === null" @click="SaveMeetings" variant="primary"
            class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0">
            {{ translations.tcSave }}
          </b-button>
          <b-button v-if="isEditMode !== null" variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0"
            @click="UpdateMeetings">
            {{ translations.tcUpdate }}
          </b-button>
          <b-button variant="tertiary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0" @click="cancel">
            {{ translations.tcCancel }}
          </b-button>
        </section>
      </page-body>
    </g-background>
    <add-meeting-location @locationSelectionMade="applyNewLocation($event)" :i18n="translations" />
    <add-meeting-location-virtual @locationVirtualSelectionMade="applyNewLocationVirtual($event)"
      :i18n="translations" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import AddMeetingLocation from '@/components/AddMeetingLocation.vue'
import AddMeetingLocationVirtual from '@/components/AddMeetingLocationVirtual.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../../services/calendarService'
import CustomeLocationSearch from '@/components/CustomeLocationSearch.vue'
import LocationSearch from '@/components/calendar/calendar-search/LocationSearch.vue'
import LocationVirtualSearch from '@/components/calendar/calendar-search/LocationVirtualSearch.vue'
import constants from '@/json/data.json'
import date_data from '@/json/date.json'
import gBackground from '@/components/gBackground.vue'
import { Loader } from '@/mixins/Loader'
import { ModelSelect } from 'vue-search-select'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SearchableDropdown from '@/components/SearchableDropdown.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  name: 'add-event',
  mixins: [Loader, translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      title: '',
      recurrenceType: null,
      defaultTime: null,
      defaultlocation: null,
      defaultlocationVirtual: null,
      selectLocation: null,
      selectLocationVirtual: null,
      selectedCordinatorForUpdate: null,
      isEditMode: null,
      isMultiDayEvent: false,
      org_key: null,
      startEndDate: { startDate: null, endDate: null },
      startEndTime: null,
      startTime: null,
      endTime: null,
      weeklyDay: 0,
      rSettings: {
        freq: null,
        config: {
          daily: {
            every: 1,
          },
          weekly: {
            every: 1,
            days: [],
          },
          monthly: {
            type: null,
            mtype_1: {
              day: 1,
              months: 1,
            },
            mtype_2: {
              when: null,
              weekday: null,
              months: 1,
            },
          },
          yearly: {
            years: 1,
            type: null,
            ytype_1: {
              month: null,
              day: 1,
            },
            ytype_2: {
              when: null,
              weekday: null,
              month: null,
            },
          },
        },
      },
      location: null,
      virtualLocation: null,
      locationTypeSelected: 'physical',
      locationTypeOptions: [],
      errorMessage: null,
      dailyshowstate: false,
      weekshowstate: false,
      monthshowstate: false,
      yearshowstate: false,
      onceshowstate: false,
      validateError: [],
      swap: {
        _dailyshowstate: 'outline-primary',
        _weekshowstate: 'outline-primary',
        _monthshowstate: 'outline-primary',
        _yearshowstate: 'outline-primary',
        _onceshowstate: 'primary',
      },
      coordinator: null,
      coordinators: [],
      event: null,
      eventTitle: null,
      eventType: [{ value: null, text: 'Event Type:' }],
      eventTypeTranslated: {},
      member: null,
      memberType: [{ value: null, text: 'Member Type:' }],
      day: 'Wednesday',
      month: 'January',
      GideonOtherMeetingGuid: '6fd79c51-e6d1-49bd-86f2-a384c7c082b4',
    }
  },
  components: {
    'add-meeting-location': AddMeetingLocation,
    'add-meeting-location-virtual': AddMeetingLocationVirtual,
    'custome-location': CustomeLocationSearch,
    gBackground: gBackground,
    LocationSearch,
    LocationVirtualSearch,
    ModelSelect,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    'searchable-dropdown': SearchableDropdown,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    handleLocationSelect(location) {
      this.location = location

      this.selectLocation = null
      this.defaultlocation = null
      this.selectLocationVirtual = null
      this.defaultlocationVirtual = null
      this.virtualLocation = null
    },
    handleVirtualLocationSelect(location) {
      this.virtualLocation = location

      this.selectLocationVirtual = null
      this.defaultlocationVirtual = null
      this.selectLocation = constants.virtual_location_org_key
      this.location = null
    },
    markSelected(type) {
      this.swap._dailyshowstate = type === 'daily' ? 'primary' : 'outline-primary'
      this.swap._onceshowstate = type === 'once' ? 'primary' : 'outline-primary'
      this.swap._yearshowstate = type === 'yearly' ? 'primary' : 'outline-primary'
      this.swap._weekshowstate = type === 'weekly' ? 'primary' : 'outline-primary'
      this.swap._monthshowstate = type === 'monthly' ? 'primary' : 'outline-primary'
    },
    setProps(obj, newVal) {
      for (var i in obj) {
        if (typeof obj[i] === 'object' && !(obj[i] instanceof Array)) {
          this.setProps(obj[i], newVal)
        } else {
          obj[i] = newVal
        }
      }
    },
    clearType(type) {
      this.setProps(this.rSettings.config.monthly, null)
      this.rSettings.config.monthly.type = type
    },
    clearTypeYear(type) {
      this.setProps(this.rSettings.config.yearly, null)
      this.rSettings.config.yearly.type = type
    },
    changereoccurstate(state) {
      this.setProps(this.rSettings, null)
      this.rSettings.freq = state
      if (state === 'monthly' && this.$refs['monthly']) {
        this.rSettings.config.monthly.type = 'type_1'
      } else if (state === 'yearly' && this.$refs['yearly']) {
        this.rSettings.config.yearly.type = 'type_1'
      }
      if (state === 'once') {
        this.dailyshowstate = false
        this.weekshowstate = false
        this.monthshowstate = false
        this.yearshowstate = false
      } else {
        this.dailyshowstate = state === 'daily' && this.$refs['daily']
        this.weekshowstate = state === 'weekly' && this.$refs['weekly']
        this.monthshowstate = state === 'monthly' && this.$refs['monthly']
        this.yearshowstate = state === 'yearly' && this.$refs['yearly']
      }
      if (this.$refs[state]) {
        this.markSelected(state)
      }
    },
    getEventCalenderSetting(org_key) {
      this.showLoader()
      calendarService
        .getEventCalenderFormSettings(org_key, 'camp')
        .then((res) => {
          //populate member type
          this.memberType = [
            ...this.memberType,
            ...res.data.MemberTypeList.map((item) => {
              return {
                value: item.vmpt_mpt_key,
                text: this.translate(item.vmpt_trn_txt),
              }
            }),
          ]
          this._memberType = this.memberType
          //populate event type
          this.eventTypeTranslated = [
            ...this.eventType,
            ...res.data.MeetingTypeList.map((item) => {
              const translatedText =
                this.translations.common['calendar-event-type'][item.mtt_key] || item.mtt_description_text
              return { value: item.mtt_key, text: translatedText }
            }),
          ]
          // populate cordinator list
          this.coordinators = [
            ...this.coordinators,
            ...res.data.CoordinatorList
              .sort((a, b) => {
                let alpha = a.sort_name.toUpperCase()
                let beta = b.sort_name.toUpperCase()
                return alpha < beta ? -1 : alpha > beta ? 1 : 0
              })
              .map((item) => {
                return { value: item.ind_key, text: item.name }
              }),
          ]
          this.showCordinator = true
          let { aid = null } = this.$router.currentRoute.params
          if (aid !== null) {
            this.eventTitle = this.eventType.find((x) => x.value === aid).text
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.closeLoader()
        })
    },
    async applyNewLocation(evt) {
      this.selectLocation = evt.key
      this.location = null
      this.defaultlocation = evt.name
      this.selectLocationVirtual = null
      this.virtualLocation = null
    },
    async applyNewLocationVirtual(evt) {
      this.selectLocationVirtual = evt.key
      this.virtualLocation = null
      this.defaultlocationVirtual = evt.name
      this.selectLocation = constants.virtual_location_org_key
      this.location = null
    },
    setLocationTypeOptions() {
      this.locationTypeOptions = [
        { text: this.translations.tcPhysicalLocation ? this.translations.tcPhysicalLocation : 'Physical Location', value: 'physical' },
        { text: this.translations.tcVirtualLocation ? this.translations.tcVirtualLocation : 'Virtual Location', value: 'virtual' }
      ]
    },
    SaveMeetings() {
      this.rSettings.config.weekly.days = this.weeklyDay
      this.validateError = []
      this.validateEventInformation()
      if (this.validateError.length > 0) {
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        let addNewMeetings = {
          start_date: this.startEndDate.startDate,
          end_date: this.startEndDate.endDate,
          mtg_title: this.eventTitle ? this.eventTitle : '',
          mtg_start_time: this.startTime,
          mtg_end_time: this.endTime,
          mtg_coordinator_ind_key: this.coordinator,
          mtg_location_org_key: !this.location ? this.selectLocation : this.location.org_key,
          mtg_location_virtual_key: !this.virtualLocation
            ? this.selectLocationVirtual
            : this.virtualLocation.lvt_key,
          mtg_mpt_key: this.member,
          mtg_mtt_key: this.event,
          org_key: this.userCampKey,
          freq: this.rSettings.freq,
          config: this.rSettings.config,
        }
        this.showLoader()
        calendarService
          .addNewEvent(addNewMeetings)
          .then((res) => {
            if (res.status === 200) {
              this.$router.push({ path: '/calendar/calendar-month' })
            } else {
              this.$swal({
                text: this.translations.tcErrorOperationFailed,
                icon: 'error',
                confirmButtonText: this.translations.tcOk || 'Ok',
              })
            }
            this.closeLoader()
          })
          .catch((err) => {
            this.closeLoader()
          })
      }
    },
    addLocation() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationModal')
    },
    addLocationVirtual() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationVirtualModal')
    },
    async getRecurrenceMeetingById(mtr_key) {
      await calendarService.getMeetingRecurrence(mtr_key).then((res) => {
        this.eventTitle = res.data.mtr_title
        this.member = res.data.mtr_mpt_key
        this.event = res.data.mtr_mtt_key
        this.coordinator = res.data.mtr_coordinator_ind_key
        this.selectLocation = res.data.mtr_location_org_key
        this.startEndDate = {
          startDate: this.formatForDatePicker(res.data.mtr_recurrence_date_range_begin_date),
          endDate: this.formatForDatePicker(res.data.mtr_recurrence_date_range_end_date),
        }
        this.startTime = res.data.mtr_recurrence_date_range_begin_date.split('T')[1]
        this.endTime = res.data.mtr_recurrence_date_range_end_date.split('T')[1]
        this.defaultTime = {
          startTime: res.data.mtr_recurrence_date_range_begin_date.split('T')[1],
          endTime: res.data.mtr_recurrence_date_range_end_date.split('T')[1],
        }
        this.changereoccurstate(res.data.vmrt_trn_txt.toLowerCase())
        this.setUpEditInfo(res.data.vmrt_trn_txt.toLowerCase(), res.data)
        this.isMultiDayEvent = this.startEndDate.startDate === this.startEndDate.endDate ? false : true
        this.defaultlocation = res.data.org_name
        this.defaultlocationVirtual = res.data.lvt_name
        this.selectLocationVirtual = res.data.mtr_location_virtual_key
        if (this.selectLocationVirtual) {
          this.locationTypeSelected = 'virtual'
        }
      })
    },
    getMeetingById(org_key, mtg_key) {
      calendarService.getMeetingSingle(org_key, mtg_key).then((res) => {
        this.eventTitle = res.data.meetings[0].mtg_title
        this.member = res.data.meetings[0].mtg_mpt_key
        this.event = res.data.meetings[0].mtg_mtt_key
        this.coordinator = res.data.meetings[0].mtg_coordinator_ind_key
        this.selectLocation = res.data.meetings[0].mtg_location_org_key
        this.startEndDate = {
          startDate: this.formatForDatePicker(res.data.meetings[0].mtg_start_date),
          endDate: this.formatForDatePicker(res.data.meetings[0].mtg_end_date),
        }
        this.startTime = res.data.meetings[0].mtg_start_date.split('T')[1]
        this.endTime = res.data.meetings[0].mtg_end_date.split('T')[1]
        this.defaultTime = {
          startTime: res.data.meetings[0].mtg_start_date.split('T')[1],
          endTime: res.data.meetings[0].mtg_end_date.split('T')[1],
        }
        this.defaultlocation = res.data.meetings[0].location
        this.defaultlocationVirtual = res.data.meetings[0].lvt_name
        this.selectLocationVirtual = res.data.meetings[0].lvt_key
        if (this.selectLocationVirtual) {
          this.locationTypeSelected = 'virtual'
        }
      })
    },
    setUpEditInfo(type, data) {
      switch (type) {
        case 'weekly':
          this.rSettings.config.weekly.every = data.mtr_recurrence_day_of_week_or_month
          if (!!data.mtr_recurrence_specific_days_of_week) {
            this.rSettings.config.weekly.days = data.mtr_recurrence_specific_days_of_week.split(',')
            this.weeklyDay = data.mtr_recurrence_specific_days_of_week.split(',')
          }
          break
        case 'daily':
          this.rSettings.config.daily.every = data.mtr_recurrence_frequency
          break
        case 'monthly':
          this.rSettings.config.monthly.type = data.mtr_option
          if (data.mtr_option === 'type_1') {
            this.rSettings.config.monthly.mtype_1.day = data.mtr_recurrence_frequency
            this.rSettings.config.monthly.mtype_1.months = data.mtr_recurrence_month_frequency
          } else {
            this.rSettings.config.monthly.mtype_2.when = data.mtr_recurrence_frequency
            this.rSettings.config.monthly.mtype_2.weekday = parseInt(data.mtr_recurrence_day_of_week_or_month)
            this.rSettings.config.monthly.mtype_2.months = data.mtr_recurrence_month_frequency
          }
          break
        case 'yearly':
          this.rSettings.config.yearly.type = data.mtr_option
          this.rSettings.config.yearly.years = data.mtr_recurrence_year_frequency
          if (data.mtr_option === 'type_1') {
            this.rSettings.config.yearly.ytype_1.day = data.mtr_recurrence_frequency
            this.rSettings.config.yearly.ytype_1.month = data.mtr_recurrence_month_frequency
          } else {
            this.rSettings.config.yearly.ytype_2.when = data.mtr_recurrence_frequency
            this.rSettings.config.yearly.ytype_2.month = data.mtr_recurrence_month_frequency
            this.rSettings.config.yearly.ytype_2.weekday = data.mtr_recurrence_day_of_week_or_month
          }
          break
      }
    },
    UpdateMeetings() {
      this.rSettings.config.weekly.days = this.weeklyDay
      this.validateError = []
      this.validateEventInformation()
      if (this.validateError.length > 0) {
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        let UpdateMeetings = {
          start_date: this.startEndDate.startDate,
          end_date: this.startEndDate.endDate,
          mtg_title: this.eventTitle ? this.eventTitle : '',
          mtg_start_time: this.startTime,
          mtg_end_time: this.endTime,
          mtg_coordinator_ind_key: this.coordinator,
          mtg_location_org_key: !this.location ? this.selectLocation : this.location.org_key,
          mtg_location_virtual_key: !this.virtualLocation
            ? this.selectLocationVirtual
            : this.virtualLocation.lvt_key,
          mtg_mpt_key: this.member,
          mtg_mtt_key: this.event,
          org_key: this.user.user.camp_key,
          freq: this.rSettings.freq,
          config: this.rSettings.config,
          is_update: {
            mtr_key: this.$router.currentRoute.params.id,
          },
        }
        if (!!this.$router.currentRoute.params.mtg_key) {
          UpdateMeetings.mtg_key = this.$router.currentRoute.params.mtg_key
        }
        this.showLoader()
        calendarService
          .addNewEvent(UpdateMeetings)
          .then((res) => {
            if (res.status === 200) {
              this.$router.push({ path: '/calendar/calendar-month' })
            } else {
              this.$swal({
                text: this.translations.tcErrorOperationFailed,
                icon: 'error',
                confirmButtonText: this.translations.tcOk || 'Ok',
              })
            }
            this.closeLoader()
          })
          .catch((err) => {
            this.closeLoader()
          })
      }
    },
    validateEventInformation() {
      if (!this.event) {
        this.validateError.push(this.translate('Event Type'))
      }
      if (!this.member) {
        this.validateError.push(this.translate('Member Type'))
      }
      if (this.event === this.GideonOtherMeetingGuid && (this.eventTitle === null || this.eventTitle === '')) {
        this.validateError.push(this.translate('Event Title'))
      }
      if (!this.location && !this.selectLocation) {
        this.validateError.push(this.translate('Location'))
      }
      if (!this.coordinator) {
        this.validateError.push(this.translate('Coordinator'))
      }
      if (!!this.startEndDate.startDate && !this.isMultiDayEvent) {
        this.startEndDate.endDate = this.startEndDate.startDate
      }
      if (!this.startEndDate || !this.startEndDate.startDate || !this.startEndDate.endDate) {
        this.validateError.push(this.translate('Start And End Date'))
      }
      if (
        !!this.startEndDate.startDate &&
        !!this.startEndDate.endDate &&
        this.startEndDate.endDate < this.startEndDate.startDate
      ) {
        this.validateError.push(this.translate('End Date cannot be before Start Date'))
      }
      if (!this.startTime || !this.endTime) {
        this.validateError.push(this.translate('Start And End Time'))
      }
      switch (this.rSettings.freq) {
        case 'daily':
          if (!this.rSettings.config.daily || !this.rSettings.config.daily.every || this.rSettings.config.daily.every < 1) {
            this.validateError.push(this.translate('Dayss')) // `Dayss` is not a typo, it translates to 'Day(s)'
          }
          break
        case 'weekly':
          if (!this.rSettings.config.weekly || !this.rSettings.config.weekly.every || this.rSettings.config.weekly.every < 1) {
            this.validateError.push(this.translate('Every Number Weeks')) // Every # Week(s)
          }
          if (!this.rSettings.config.weekly || !this.rSettings.config.weekly.days) {
            this.validateError.push(this.translate('Dayss')) // `Dayss` is not a typo, it translates to 'Day(s)'
          }
          break
        case 'monthly':
          if (this.rSettings.config.monthly.type === 'type_1') {
            if (!this.rSettings.config.monthly.mtype_1 || !this.rSettings.config.monthly.mtype_1.day || this.rSettings.config.monthly.mtype_1.day < 1) {
              this.validateError.push(this.translate('Day'))
            }
            if (!this.rSettings.config.monthly.mtype_1 || !this.rSettings.config.monthly.mtype_1.months || this.rSettings.config.monthly.mtype_1.months < 1) {
              this.validateError.push(this.translate('Months')) // Month(s)
            }
          }
          if (this.rSettings.config.monthly.type === 'type_2') {
            if (!this.rSettings.config.monthly.mtype_2 || !this.rSettings.config.monthly.mtype_2.when) {
              this.validateError.push(this.translate('Week'))
            }
            if (!this.rSettings.config.monthly.mtype_2 || this.rSettings.config.monthly.mtype_2.weekday == null) {
              this.validateError.push(this.translate('Weekday'))
            }
            if (!this.rSettings.config.monthly.mtype_2 || !this.rSettings.config.monthly.mtype_2.months || this.rSettings.config.monthly.mtype_2.months < 1) {
              this.validateError.push(this.translate('Months')) // Month(s)
            }
          }
          break
        case 'yearly':
          if (!this.rSettings.config.yearly.years || this.rSettings.config.yearly.years < 1) {
            this.validateError.push(this.translate('Recur Every Number Years')) // Recur Every # Year(s)
          }
          if (this.rSettings.config.yearly.type === 'type_1') {
            if (!this.rSettings.config.yearly.ytype_1 || !this.rSettings.config.yearly.ytype_1.month) {
              this.validateError.push(this.translate('Month'))
            }
            if (!this.rSettings.config.yearly.ytype_1 || !this.rSettings.config.yearly.ytype_1.day || this.rSettings.config.yearly.ytype_1.day < 1) {
              this.validateError.push(this.translate('Day'))
            }
          }
          if (this.rSettings.config.yearly.type === 'type_2') {
            if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.when) {
              this.validateError.push(this.translate('Week'))
            }
            if (!this.rSettings.config.yearly.ytype_2 || this.rSettings.config.yearly.ytype_2.weekday == null) {
              this.validateError.push(this.translate('Weekday'))
            }
            if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.month) {
              this.validateError.push(this.translate('Month'))
            }
          }
          break
      }
    },
    cancel() {
      this.$router.push({ path: '/calendar/calendar-month' })
    },
    translate(text) {
      // helper function to shorten a translation lookup
      // text should be a value that exists in `this.translations`, IE 'monday', 'january', 'third'
      // This method will convert the `text` into something like `tcMonday`, `tcJanuary`, `tcThird`
      return this.translations[this.convertValForTranslation(text)]
    },
  },
  watch: {
    rSettings: {
      handler: function (value, oldvalue) { },
      deep: true,
    },
    coordinator: function (val, old) { },
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userCountryKey: 'user/userCountryKey',
    }),
    days() {
      return date_data.days.map((dd) => {
        return { value: dd.weekday, text: this.translate(dd.text) }
      })
    },
    months() {
      return date_data.months.map((mm) => {
        return { value: mm.value, text: this.translate(mm.text) }
      })
    },
    ordinal() {
      return date_data.ordinal.map((o) => {
        return { value: o.value, text: this.translate(o.text) }
      })
    },
    locationDisplay() {
      if (!this.location && !this.defaultlocation) {
        return this.translations.tcNoLocationSelected || 'No Location Selected'
      } else if (!!this.location && !!this.location.org_name) {
        return this.location.org_name
      } else {
        return this.defaultlocation
      }
    },
    locationVirtualDisplay() {
      if (!this.virtualLocation && !this.defaultlocationVirtual) {
        return this.translations.tcNoLocationSelected || 'No Location Selected'
      } else if (!!this.virtualLocation && !!this.virtualLocation.lvt_name) {
        return this.virtualLocation.lvt_name
      } else {
        return this.defaultlocationVirtual
      }
    },
  },
  async created() {
    this.setLoadingStatus(true)
    await Promise.all([
      await this.getViewTranslations('buttons', 'date-time', 'forms'),
      await this.getComponentTranslations('add-meeting-location', 'add-meeting-location-virtual', 'custome-location-search', 'location-search', 'location-virtual-search'),
      await this.getComponentTranslations('common.calendar-event-type'),
      await (async () => {
        if (!!this.$router.currentRoute.params.aid) {
          let { aid } = this.$router.currentRoute.params
          this.event = aid
        }
        await this.getEventCalenderSetting(this.user.user.camp_key)
        this.isEditMode = this.$router.currentRoute.params.type
        if (!!this.isEditMode) {
          //edit
          if (this.isEditMode === 'once' || this.isEditMode === 'single') {
            this.title = this.translations.tcEditEvent
            this.recurrenceType = this.$router.currentRoute.params.type
            this.getMeetingById(this.user.user.camp_key, this.$router.currentRoute.params.mtg_key)
          } else {
            this.title = this.translations.tcEditEvent
            this.recurrenceType = this.$router.currentRoute.params.type
            this.getRecurrenceMeetingById(this.$router.currentRoute.params.id)
          }
        } else {
          //add
          this.isEditMode = null
          this.title = this.translations.tcAddEvent
          this.rSettings.freq = 'once'
        }
      })(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.stripReadableText(results[2])
      this.$set(this.translations, 'components', componentTranslations)
      this.eventType[0].text = this.translations.tcEventType + ':'
      this.memberType[0].text = this.translations.tcMemberType + ':'
      this.setLocationTypeOptions()
      this.setLoadingStatus(false)
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

label.custom-control-label {
  width: 230px;
}

.add-template {
  h1 {
    margin-bottom: 34px;
  }

  hr {
    margin: 60px 0 35px;
  }

  @include breakpoint(sm) {
    .page-header {
      text-align: center;

      h1 {
        font-size: 42px;
      }
    }
  }

  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }

  .g-background {
    margin: 90px auto 104px;
  }

  .h-style-1 {
    margin-bottom: 10px;
  }

  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;

        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }

      .custom-checkbox {
        padding-left: 34px;
        line-height: 34px;

        label {
          font-size: 16px;

          &:before {
            left: -34px;
            border-radius: 0;
            width: 24px;
            height: 24px;
            border-color: #636363;
          }

          &:after {
            left: -34px;
            height: 24px;
            width: 24px;
          }
        }

        .custom-control-input:checked~.custom-control-label::after {
          background-color: #003946;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        margin-bottom: 15px !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.add-event {
  .btn-group {
    margin-bottom: 20px;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }

  .event-type-form,
  .member-type-form {
    display: flex;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    .custom-select {
      margin-right: 20px;
      width: 200px;

      @include breakpoint(sm) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
    }

    #event-input {
      width: 340px;

      @include breakpoint(sm) {
        width: 100%;
      }
    }
  }

  .member-type-form {
    margin-bottom: 40px;
  }

  .event-type-form {
    margin-bottom: 10px;

    @include breakpoint(sm) {
      .custom-select {
        margin-bottom: 10px;
      }
    }
  }

  .city-state-zip {
    >div {
      display: flex;

      @include breakpoint(sm) {
        flex-wrap: wrap;
      }

      .form-control {
        flex: 1 1 auto;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        &.custom-select {
          margin-right: 20px;

          @include breakpoint(sm) {
            max-width: 100%;
            margin-right: 0;
          }
        }

        @include breakpoint(sm) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .form-control {
    font-weight: bold;
  }

  .coordinator {
    display: flex;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    .form-control {
      margin-right: 20px !important;

      @include breakpoint(sm) {
        margin-right: 0 !important;
        margin-bottom: 10px;
      }

      &.custom-select {
        width: 300px;

        @include breakpoint(sm) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .form-block {
    margin-bottom: 55px;
  }

  .every-week-on {
    >div {
      display: flex;
      align-items: center;

      .every-input {
        width: 55px;
      }

      span {
        font-weight: bold;
        text-transform: uppercase;

        &:first-of-type {
          margin-right: 10px;
        }

        &:last-of-type {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .custom-select {
        width: 180px;
      }
    }
  }
}

.dp-container {
  display: flex;

  .dp-startend {
    width: 50%;

    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.contact_date {
  width: 100%;

  @include breakpoint(sm) {
    width: 100%;
  }
}
</style>
